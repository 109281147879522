import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Link from "@material-ui/core/Link"
import { graphql, useStaticQuery } from "gatsby"
const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(1, 1),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}))


const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      info: contentfulText(title: { eq: "Юридическая информация (низ сайта (подвал))" }) {
        text {
          text
        }
      }
      site: contentfulText(title: { eq: "Адрес сайта (домена)" }) {
        text {
          text
        }
      }
    }
  `)
  const footerText = data.info.text.text;
  const siteName = data.site.text.text;
  const classes = useStyles();
  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align={"center"}>
        {"Copyright © "}
        <Link color="inherit" href={`https://${siteName}`}>
          {siteName}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    )
  }
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Typography variant="body2" color="textSecondary" align={"center"}>
          {footerText}
        </Typography>
        <Copyright />
      </Container>
    </footer>
  )
}
export default Footer
