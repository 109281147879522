import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Image from "./image"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HomeIcon from '@material-ui/icons/Home';
import ListItemText from "@material-ui/core/ListItemText"
import Box from "@material-ui/core/Box"
import {navigate} from 'gatsby';
import { YMInitializer } from 'react-yandex-metrika';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyItems: 'center'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

}));


const Header = ({siteTitle}) => {
  const classes = useStyles();
  const [isDrawer, setIsDrawer] = React.useState(false);
  return <header>
    <YMInitializer accounts={[69113236]} options={{webvisor: true, clickmap:true, trackLinks:true, accurateTrackBounce:true}} version="2" />
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <Drawer anchor='right' open={isDrawer} onClose={()=>setIsDrawer(false)}>
          <List>

              <ListItem button onClick={()=>{
                navigate('/');
              }}>
                <ListItemIcon><HomeIcon /> </ListItemIcon>
                <ListItemText primary="Главная" />
              </ListItem>
              <ListItem button onClick={()=>{
                navigate('/contacts');
              }}>
               <ListItemIcon><ContactMailIcon /> </ListItemIcon>
               <ListItemText primary="Контакты" />
              </ListItem>
          </List>
        </Drawer>
        <Box className={classes.root}>
        <Image/>
        </Box>
        {/*<Hidden mdUp>*/}
        {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={()=>setIsDrawer(!isDrawer)}>*/}
        {/*  <MenuIcon />*/}
        {/*</IconButton>*/}
        {/*</Hidden>*/}
      </Toolbar>
    </AppBar>
  </header>
}


export default Header
