import React from "react"
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Header from "./header"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Box from "@material-ui/core/Box"
import Footer from "./Footer"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2b2a29'
    },
    secondary: {
      main: '#f44336',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
    <Box className={classes.root}>
      <CssBaseline />
      <Header/>
      <Container component="main" className={classes.main} maxWidth="false">
        {children}
      </Container>
      <Footer/>
    </Box>
    </ThemeProvider>
  )
}

export default Layout
